import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'

const resource = 'api/v1/document'

export default {
    getConfidentialityDocument(): Promise<AxiosResponse<IServerResponse<string>>> {
        return axios.get(`${resource}/confidentiality`)
    },

    getCguDocument(): Promise<AxiosResponse<IServerResponse<string>>> {
        return axios.get(`${resource}/cgu`)
    },
}
